import axios from "axios";
import { Message } from "element-ui";
import { getBaseUrl } from "@/api";
const http = axios.create({
  timeout: 30000,
  baseURL: getBaseUrl()
});

let httpCode = {
  400: "请求参数错误",
  401: "权限不足",
  403: "服务器拒绝本次访问",
  404: "请求资源未找到",
  500: "内部服务器错误",
  501: "服务器不支持该请求中使用的方法",
  502: "网关错误",
  504: "网关超时"
};

http.interceptors.request.use(
  config => {
    config.headers = {
      ...config.headers,
      "Content-Type": "application/json"
    };
    return config;
  },
  error => {
    return Promise.reject(error);
  }
);

http.interceptors.response.use(
  response => {
    const { data, status } = response;
    let ok = false;
    // 请求成功
    if (status == "200") {
      if (response.data.status === 0) {
        ok = true;
      }
    } else {
      Message({
        type: "error",
        message: data.message
      });
      ok = false;
    }
    return Promise.resolve({
      ok,
      data: response.data.data ? response.data.data : data,
      message: response.data.data ? response.data.data.message : data.message
    });
  },
  error => {
    if (error.response) {
      let tips =
        error.response.status in httpCode
          ? httpCode[error.response.status]
          : error.response.data.message;
      Message({
        message: tips,
        type: "error"
      });
      return Promise.reject({
        ok: false,
        message: tips,
        err: error.response
      });
    } else {
      Message({
        message: "请求超时，请刷新重试",
        type: "error"
      });
      return Promise.reject({
        ok: false,
        message: "请求超时，请刷新重试",
        err: error.response
      });
    }
  }
);

export { http, axios };
